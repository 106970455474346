<template>
  <div class="viewDashBoard">
    Maintenance mode
  </div>
</template>

<script>
export default {
  components: {},
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  data() {
    return {};
  },
  mounted() {
    document.title = this.$router.app._route.meta.pageTitle;
  },
  methods: {}
};
</script>
